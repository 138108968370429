import React from 'react';
import { Navigate } from 'react-router-dom';
import { isTokenValid } from './utils/auth'; // Import the token validation function

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');

  // If token is invalid, redirect to login
  if (!token || !isTokenValid(token)) {
    return <Navigate to="/login" />;
  }

  // If token is valid, render the protected component
  return children;
};

export default ProtectedRoute;

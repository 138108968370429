import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import ProtectedRoute from './ProtectedRoute'; // Import ProtectedRoute
import Videos from './Videos'; // Import the new Videos component
import Pictures from './Pictures'; // Import the new Pictures component
import Mp3 from './Mp3'; // Import the new Mp3 component

function App() {
  return (
    <Router>
      <Routes>
        {/* Public login route */}
        <Route path="/login" element={<Login />} />

        {/* Protected dashboard route */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        {/* Protected videos route */}
        <Route
          path="/videos"
          element={
            <ProtectedRoute>
              <Videos />
            </ProtectedRoute>
          }
        />

        {/* Protected pictures route */}
        <Route
          path="/pictures"
          element={
            <ProtectedRoute>
              <Pictures />
            </ProtectedRoute>
          }
        />

        {/* Protected mp3 route */}
        <Route
          path="/mp3"
          element={
            <ProtectedRoute>
              <Mp3 />
            </ProtectedRoute>
          }
        />

        {/* Redirect root path to login */}
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;

import React from 'react';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const navigate = useNavigate();
  const username = 'John Doe'; // Replace with dynamic user data

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center">
      {/* Welcome Message */}
      <h1 className="text-4xl font-semibold mb-8 text-center text-gray-800">
        Welcome, {username}!
      </h1>

      {/* Content Type Selection */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 w-full max-w-4xl px-4">
        {/* Videos Card */}
        <div
          onClick={() => navigate('/videos')}
          className="bg-white rounded-lg shadow-lg p-6 text-center hover:bg-indigo-500 hover:text-white transition duration-300 cursor-pointer"
        >
          <h2 className="text-2xl font-semibold mb-4">Videos</h2>
          <p className="text-gray-500">Explore videos</p>
        </div>

        {/* Pictures Card */}
        <div
          onClick={() => navigate('/pictures')}
          className="bg-white rounded-lg shadow-lg p-6 text-center hover:bg-indigo-500 hover:text-white transition duration-300 cursor-pointer"
        >
          <h2 className="text-2xl font-semibold mb-4">Pictures</h2>
          <p className="text-gray-500">Browse your pictures</p>
        </div>

        {/* MP3 Card */}
        <div
          onClick={() => navigate('/mp3')}
          className="bg-white rounded-lg shadow-lg p-6 text-center hover:bg-indigo-500 hover:text-white transition duration-300 cursor-pointer"
        >
          <h2 className="text-2xl font-semibold mb-4">MP3</h2>
          <p className="text-gray-500">Listen to your audio files</p>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;

import { jwtDecode } from 'jwt-decode'; // Use named import for jwtDecode

// Function to check if the token is valid and not expired
export const isTokenValid = (token) => {
  if (!token) {
    return false;
  }
  
  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Get current time in seconds
    return decodedToken.exp > currentTime; // Check if token expiration is greater than the current time
  } catch (error) {
    return false; // If there's an error decoding the token, return false
  }
};

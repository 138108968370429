import React from 'react';

function Videos() {
  return (
    <div>
      <h1>Videos Page</h1>
      <p>Here you can view videos.</p>
    </div>
  );
}

export default Videos;

import React, { useState } from 'react';

function ImageViewer({ imageUrl, onClose }) {
  const [scale, setScale] = useState(1); // Initialize scale state

  if (!imageUrl) return null; // Early return if imageUrl is undefined

  // Handle zooming with mouse wheel
  const handleWheel = (event) => {
    event.preventDefault();
    const delta = event.deltaY < 0 ? 0.1 : -0.1; // Scroll up to zoom in, down to zoom out
    setScale((prev) => Math.min(Math.max(prev + delta, 1), 3)); // Clamp scale between 1 and 3
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
      onWheel={handleWheel} // Add wheel event listener
    >
      <div className="relative">
        <img
          src={imageUrl}
          alt="Viewing"
          className="max-w-full max-h-full"
          style={{ transform: `scale(${scale})`, transition: 'transform 0.2s' }}
        />
        <button
          onClick={onClose}
          className="absolute top-4 right-4 bg-white text-black px-2 py-1 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default ImageViewer;
